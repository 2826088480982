@mixin generate-sizes-utilities($prefix: 'fx') {
  $widths: (
    'w-25': 25%,
    'w-50': 50%,
    'w-75': 75%,
    'w-100': 100%,
    'w-auto': auto,
    'w-screen': 100vw
  );

  $heights: (
    'h-25': 25%,
    'h-50': 50%,
    'h-75': 75%,
    'h-100': 100%,
    'h-auto': auto,
    'h-screen': 100vh
  );

  @each $name, $value in $widths {
    .#{$prefix}\:#{$name} { width: $value; }
  }

  @each $name, $value in $heights {
    .#{$prefix}\:#{$name} { height: $value; }
  }
}
