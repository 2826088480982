@mixin generate-spacing-utilities($base-unit: 24px, $prefix: "fx") {
  // Utility multipliers
  $spacing-scales: (
    0: 0,
    "0-5": 0.5,
    "0-75": 0.75,
    1: 1,
    "1-5": 1.5,
    2: 2,
    "2-5": 2.5,
    3: 3,
    "3-5": 3.5,
    4: 4,
    5: 5,
    6: 6,
  );

  // Generating padding utilities
  @each $scale, $multiplier in $spacing-scales {
    .#{$prefix}\:p-#{$scale} {
      padding: $base-unit * $multiplier;
    }
    .#{$prefix}\:pt-#{$scale} {
      padding-top: $base-unit * $multiplier;
    }
    .#{$prefix}\:pr-#{$scale} {
      padding-right: $base-unit * $multiplier;
    }
    .#{$prefix}\:pb-#{$scale} {
      padding-bottom: $base-unit * $multiplier;
    }
    .#{$prefix}\:pl-#{$scale} {
      padding-left: $base-unit * $multiplier;
    }
    .#{$prefix}\:px-#{$scale} {
      padding-left: $base-unit * $multiplier;
      padding-right: $base-unit * $multiplier;
    }
    .#{$prefix}\:py-#{$scale} {
      padding-top: $base-unit * $multiplier;
      padding-bottom: $base-unit * $multiplier;
    }
  }

  // Generating margin utilities
  @each $scale, $multiplier in $spacing-scales {
    .#{$prefix}\:m-#{$scale} {
      margin: $base-unit * $multiplier;
    }
    .#{$prefix}\:mt-#{$scale} {
      margin-top: $base-unit * $multiplier;
    }
    .#{$prefix}\:mr-#{$scale} {
      margin-right: $base-unit * $multiplier;
    }
    .#{$prefix}\:mb-#{$scale} {
      margin-bottom: $base-unit * $multiplier;
    }
    .#{$prefix}\:ml-#{$scale} {
      margin-left: $base-unit * $multiplier;
    }
    .#{$prefix}\:mx-#{$scale} {
      margin-left: $base-unit * $multiplier;
      margin-right: $base-unit * $multiplier;
    }
    .#{$prefix}\:my-#{$scale} {
      margin-top: $base-unit * $multiplier;
      margin-bottom: $base-unit * $multiplier;
    }
  }
}
